.sidebar {
  position: fixed;
  top: 60px; /* Height of your navigation bar */
  left: -250px; /* Initially hidden */
  width: 250px; /* Width of the sidebar */
  height: 100%;
  padding-left: 16px;
  overflow-y: auto;
 /* Enable scrolling if content exceeds height */
  z-index: 9999;
  transition: left 0.3s ease;
  background-color: white;
 /* Add transition for left property */
}

.sidebar.open {
  left: 0; /* Slide in from the left when open */
}

.sidebar-content {

  padding-top: 20px;
}

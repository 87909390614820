@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0px !important;
  padding: 0;
  font-family: 'Montserrat', sans-serif;

}

/* width */
::-webkit-scrollbar {
    width: 12px;
    height: 80px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:#222A35;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #575C66;
    border-radius: 6px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #626970;
  }
  .link-span{
    color: grey;
   display: inline-block;
    
  }

  /* .flex-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 22rem));
    gap: 20px;
    justify-content: start;
    margin-bottom: 20px;
    margin-left: 252px;
  
  } */
  .flex-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 22rem));
    gap: 20px;
    justify-content: start;
    margin-bottom: 20px;
    padding-left: 16px;
  }
  
  @media (min-width: 768px) {
    /* Apply margin-left only when the screen width is larger than 768px (not on mobile) */
    .flex-container {
      margin-left: 270px;
    }
  }
  .icon-link{
    text-decoration: none;
    color: #222A35;
  
  }
  .link-behavior{
    cursor: pointer;
  }
  
  .product-card{
    /* height: 270px; */
   
  }
.text-container{
  text-align: center;
}
  .product-image{
    /* background-color: #e3e6e6 !important; */
    padding-bottom: 3px;
    width: 200px;
    height: auto;
    display: block; /* Ensure the image is treated as a block element */
    margin: 0 auto;
  
  }
  .card-details{
    font-weight: 500;
   
  }
  .title-link{
    text-decoration: none;
    color: black;
  }
  .details-container{
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 22rem));
    gap: 20px;
    justify-content: center;
  }
  .buy-button {
    background-color: #5cb85c; /* Background color */
    color: #fff; /* Text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Add padding */
    font-size: 16px; /* Font size */
    font-weight: bold; /* Font weight */
    border-radius: 4px; /* Add border radius for rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Add transition effect */
  }
  
  .buy-button:hover {
    background-color: #4cae4c; /* Change background color on hover */
  }
  .buy-card{
    display: block;
  }
  .download-div {
    width: 100%;
    display: flex; /* Use flexbox */
    color: green;
    flex-direction: column;
  }
  .sidebar-item {
    display: flex;
    padding: 10px;
    cursor: pointer;
  }
  
  .sidebar-icon {
    font-size: 1.5rem;
    margin-right: 10px;
  }
  
  .sidebar-text {
    font-size: 1rem;
  }
  
  
